.main-section {
  &.single-page {
    height: calc(100vh - 80px);
    @media (max-width: 578px) {
      height: calc(100vh - 90px);
    }
    &.thanks {
      &:before {
        border-bottom-color: map_get($ui-colors, color-4);
      }
      &:after {
        border-bottom-color: map_get($ui-colors, color-4);
      }
      .main-text {
        h1 {
          text-transform: none;
          //color: $white-color;
          span {
            text-transform: uppercase;
          }
        }
      }
    }
    &.error {
      &:before {
        border-bottom-color: map_get($ui-colors, color-10);
      }
      &:after {
        border-bottom-color: map_get($ui-colors, color-10);
      }
    }
  }
}