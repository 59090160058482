@mixin underline-from-left($color, $height, $duration) {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: 0 0 1px hsla(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: $color;
    height: $height;
    transition-property: right;
    transition-duration: $duration;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:active {
    &:before {
      right: 0;
    }
  }
}

@mixin bounce-to-top($color, $duration) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition-duration: $duration;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    &:before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }
}

@mixin underline-from-center($color, $height, $duration) {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    bottom: 0;
    background: $color;
    height: $height;
    transition-property: left, right;
    transition-duration: $duration;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      left: 0;
      right: 0;
    }
  }
}

@mixin background-opacity($color, $opacity) {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color, $opacity);
    //z-index: -1;
  }
}

