//--> COLORS <--//
$ui-colors: (
  color-1: #72C7E7,
  //color-2: #72C7E7,
  color-3: #FFC433,
  color-4: #1D1D1F,
  color-5: #E59F33,
  color-6: #222222,
  color-7: #F3F3F3,
  color-8: #FFD878,
  color-9: #2C3366,
  color-10: #37AFDD,
  color-11: #999999,
  color-12: #666666,
  color-13: #f3f3f3,
);

$twitter-color: #2FE3FD;
$facebook-color: #315D9F;
$google-plus-color: #DC4538;
//$instagram-color: #D42A64;
//$facebook-hover: #2D4373;
$linkedin-color: #0077b5;
//$youtube-color: #bb0000;

//--> /COLORS <--//

$white-color: #fff;
$black-color: #000;
$ireevo-color: #FF007F;
$error-color: #EE3A53;


//--> SITE'S FONTS <--//

$raleway-regular: "Raleway-Regular", sans-serif;
$raleway-bold: "Raleway-Bold", sans-serif;
$raleway-medium: "RalewayMedium", sans-serif;
$raleway-medium-italic: "RalewayMedium-Italic", sans-serif;
$raleway-semibold: "RalewaySemiBold", sans-serif;
$raleway-thin: "RalewayThin", sans-serif;


//--> /SITE'S FONTS <--//
