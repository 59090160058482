.video-container {
  position: relative;
  width: 100%;
}

video {
  display: block;
  width: 100%;
}

.top-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  &:hover {
    //background-color: rgba(#282828, 0.3);
  }
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: auto;
  iframe {
    min-height: 300px;
  }
}
