.main-section {
  position: relative;
  width: 100%;
  background-color: map_get($ui-colors, color-7);
  height: 62vw;
  min-height: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 0;
    border-bottom: 140px solid map_get($ui-colors, color-1);
    border-right: 70vw solid transparent;
    @media (max-width: 767px) {
      border-bottom-width: 14vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    height: 0;
    border-bottom: 130px solid map_get($ui-colors, color-1);
    border-left: 30vw solid transparent;
    @media (max-width: 767px) {
      border-bottom-width: 14vw;
    }
  }
  .main-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12%;
    max-width: 100%;
    @media (max-width: 420px) {
      left: 10%;
    }
    h1 {
      font: 5.8vw $raleway-thin;
      color: map_get($ui-colors, color-4);
      text-transform: uppercase;
      line-height: .9;
      margin: 1rem 0;
      @media (max-width: 578px) {
        font-size: 2em;
      }
      span {
        font: bold 5.3vw $raleway-bold;
        @media (max-width: 578px) {
          font-size: 1.8rem;
        }
      }
    }
    .about {
      position: relative;
      font: rem(26px) $raleway-semibold;
      color: map_get($ui-colors, color-4);
      padding-bottom: 5px;
      transition: all .2s;
      @media (max-width: 991px) {
        font-size: 1.3rem;
      }
      @media (max-width: 767px) {
        font-size: 1.1rem;
      }
      @media (max-width: 578px) {
        font-size: rem(15px);
      }
      &:hover {
        color: map_get($ui-colors, color-1);
        &:before {
          background-color: map_get($ui-colors, color-1);
        }
        &:after {
          color: map_get($ui-colors, color-1);
        }
      }
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        height: 1px;
        width: 85%;
        background-color: map_get($ui-colors, color-4);
        transition: all .2s;
      }
      &:after {
        position: absolute;
        content: '\f0da';
        font-family: "FontAwesome";
        top: calc(100% + 8px);
        right: 0;
        color: map_get($ui-colors, color-4);
        font-size: rem(14px);
        transition: all .2s;
      }
    }
  }
}

.about-section {
  position: relative;
  width: 100%;
  padding: 6em 0 10em;
  background-color: map_get($ui-colors, color-1);
  @media (max-width: 991px) {
    padding: 4em 0 6em;
  }
  &:before {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    height: 0;
    border-top: 130px solid map_get($ui-colors, color-1);
    border-right: 30vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 14vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 100%;
    right: 0;
    height: 0;
    border-top: 150px solid map_get($ui-colors, color-1);
    border-left: 69vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 15vw;
    }
  }
  > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-block {
    width: 1200px;
    max-width: 100%;
    background-color: $white-color;
    padding: 5em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 991px) {
      padding: 3em 2em;
    }
    @media (max-width: 490px) {
      padding: 2em 1em;
    }
    .about-text {
      display: flex;
      justify-content: space-around;
      margin: 1em 0 3em;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media (max-width: 767px) {
        margin-bottom: 1.5em;
      }
      .left-text {
        width: 36%;
        text-align: right;
        font: 1.5em $raleway-bold;
        color: map_get($ui-colors, color-4);
        @media (max-width: 1199px) {
          width: 30%;
        }
        @media (max-width: 991px) {
          width: 100%;
          text-align: left;
          margin-bottom: 1.5em;
          font-size: 1.3em;
        }
        @media (max-width: 578px) {
          font-size: 1em;
        }
      }
      .right-text {
        width: 60%;
        @media (min-width: 768px) {
          display: flex;
        }
        @media (max-width: 1199px) {
          width: 64%;
        }
        @media (max-width: 991px) {
          width: 100%;
        }
        p {
          @media (min-width: 768px) {
            margin: 0 10px;
          }
          @media (max-width: 578px) {
            font-size: em(15px);
          }
        }
      }
    }
  }
}

.consultation-section {
  position: relative;
  width: 100%;
  padding: calc(3em + 150px) 0 10em;
  @media (max-width: 1024px) {
    padding-bottom: 16em;
  }
  @media (max-width: 767px) {
    padding-top: calc(2em + 15vw);
    padding-bottom: 9em;
  }
  .section-headline {
    margin-bottom: 3em;
    @media (max-width: 991px) {
      margin-bottom: 2em;
    }
    @media (max-width: 578px) {
      margin-bottom: 1.2em;
    }
  }
  .grid-item-row {
    position: relative;
    display: flex;
    @media (max-width: 490px) {
      margin-bottom: 2em;
    }
    @media (max-width: 370px) {
      margin-bottom: 4em;
    }
    .grid-item {
      position: relative;
      width: 45%;
      @media (max-width: 490px) {
        width: 100%;
        text-align: center;
      }
      &.shadow {
        .image {
          img {
            box-shadow: -1em -1em 0 map_get($ui-colors, color-8);
            @media (max-width: 578px) {
              box-shadow: -.5em -.5em 0 map_get($ui-colors, color-8);
            }
          }
        }
      }
      .image {
        overflow: hidden;
        img {
          transition: all .2s;
          @media (max-width: 991px) {
            width: 350px;
            max-width: 100%;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .item-info {
        position: absolute;
        top: 50%;
        width: max-content;
        transform: translateY(-50%);
        padding-bottom: .8em;
        text-align: left;
        @media (max-width: 490px) {
          position: relative;
          top: 0;
          transform: none;
        }
        p {
          font-size: em(14px);
          margin-bottom: 0;

          @media (max-width: 578px) {
            font-size: em(13px);
          }
          @media (min-width: 371px) {
            margin-left: 2.5rem;
          }
        }
        h4 {
          font: bold 1.5rem $raleway-bold;
          a {
            transition: all 0.2s;
            color: map_get($ui-colors, color-4);
            &:hover {
              color: map_get($ui-colors, color-1);
              //text-decoration: underline;
            }
          }
          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
          @media (max-width: 578px) {
            font-size: 1.2em;
          }
        }
        @media (min-width: 491px) {
          &:before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            height: 1px;
            width: 85px;
            background-color: map_get($ui-colors, color-4);
          }
          &:after {
            position: absolute;
            content: '\f0d9';
            font-family: "FontAwesome";
            top: calc(100% + 5px);
            left: 0;
            color: map_get($ui-colors, color-4);
            font-size: rem(14px);
          }
        }

      }
    }
    &:nth-child(2n+1) {
      justify-content: flex-end;
      .grid-item {
        .item-info {
          right: calc(100% - 1.6em);
          text-align: right;
          @media (max-width: 490px) {
            right: 0;
            margin: 1em auto 0;
            text-align: left;
          }
          @media (min-width: 491px) {
            &:before,
            &:after {
              left: auto;
              right: 0;
            }
            &:after {
              content: '\f0da';
            }
          }
          p {
            margin-left: 0;
            @media (min-width: 491px) {
              margin-right: 2.5em;
            }
          }
        }
      }
    }
    &:nth-child(2n) {
      justify-content: flex-start;
      .grid-item {
        text-align: right;
        @media (max-width: 490px) {
          text-align: center;
        }
        @media (max-width: 370px) {
          text-align: center;
        }
        .item-info {
          left: calc(100% - 1.6em);
          @media (max-width: 490px) {
            left: 0;
            margin: 1em auto 0;
          }
        }
      }
    }
    &:not(:first-child) {
      .grid-item {
        margin-top: -8em;
        @media (max-width: 991px) {
          margin-top: -4em;
        }
        @media (max-width: 767px) {
          margin-top: -2em;
        }
        @media (max-width: 490px) {
          margin-top: 0;
        }
      }
    }
  }
  .more-btn {
    position: absolute;
    bottom: .5em;
    right: 20%;
    @media (max-width: 1024px) {
      right: auto;
      bottom: auto;
      top: calc(100% + 3em);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.books-section {
  position: relative;
  width: 100%;
  //height: 52vw;
  @include background-opacity(map_get($ui-colors, color-6),.6);
  padding: 5em 0 7em;
  @media (max-width: 1199px) {
    padding-top: 1em;
  }
  @media (max-width: 991px) {
    .wrapper {
      padding: 0 15px;
    }
  }
  @media (max-width: 767px) {
    padding: 3em 0 5em;
  }
  @media (max-width: 578px) {
    padding-top: 2em;
  }
  .book-slide {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin: 1em 1em 0;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .info-item {
    width: 60%;
    @media (max-width: 991px) {
      width: 55%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    h3 {
      position: relative;
      font: bold em(66px) $raleway-bold;
      text-transform: uppercase;
      color: $white-color;
      padding-bottom: 1rem;
      margin-bottom: 4.5rem;
      @media (max-width: 1439px) {
        font-size: 3.6em;
      }
      @media (max-width: 1199px) {
        font-size: 3.2rem;
      }
      @media (max-width: 991px) {
        font-size: 2.5rem;
      }
      @media (max-width: 578px) {
        font-size: 2.2rem;
      }
      @media (max-width: 420px) {
        font-size: 2rem;
      }
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 3px;
        width: 125px;
        background-color: map_get($ui-colors, color-3);
      }
      &:after {
        position: absolute;
        content: '\f0da';
        font-family: "FontAwesome";
        top: calc(100% + 5px);
        left: 118px;
        color: map_get($ui-colors, color-3);
        font-size: rem(14px);
      }
    }
    .small {
      margin-top: 1rem;
      padding-right: 5em;
      @media (max-width: 767px) {
        padding-right: 0;
      }
      p {
        quotes: "“" "”";
        font: rem(26px) $raleway-semibold;
        color: $white-color;
        line-height: 1.6;
        @media (max-width: 1439px) {
          font-size: 1.4rem;
        }
        @media (max-width: 1199px) {
          font-size: 1.2rem;
        }
        @media (max-width: 991px) {
          font-size: 1rem;
        }
        &:first-child {
          &:before {
            content: open-quote;
            font-size: rem(80px);
            color: map_get($ui-colors, color-3);
            line-height: 0.1em;
            vertical-align: -0.6rem;
            margin-right: 0.1em;
            @media (max-width: 1439px) {
              font-size: 4rem;
            }
            @media (max-width: 1199px) {
              font-size: 3.5rem;
            }
          }
        }
        &:last-child {
          &:after {
            content: close-quote;
            font-size: rem(80px);
            color: map_get($ui-colors, color-3);
            line-height: 0.1em;
            vertical-align: -0.4em;
            margin-left: 0.5rem;
            @media (max-width: 1439px) {
              font-size: 4rem;
            }
            @media (max-width: 1199px) {
              font-size: 3.5rem;
            }
          }
        }
      }
    }
    .comment {
      margin-left: 1em;
      .name {
        font: bold 1.5rem $raleway-bold;
        color: map_get($ui-colors, color-3);
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 1.3rem;
        }
      }
      .work {
        font: rem(14px) $raleway-regular;
        color: $white-color;
      }
    }
  }
  .cover-item {
    width: 40%;
    text-align: center;
    @media (max-width: 991px) {
      width: 45%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 4em;
    }
    .btn {
      width: 270px;
      margin: 0 auto;
      & + .btn {
        margin-top: 1em;
      }
      @media (max-width: 420px) {
        width: 240px;
        min-width: 240px;
      }
    }
    .cover-book {
      position: relative;
      width: 470px;
      max-width: 100%;
      margin: 0 auto 5.5em;
      height: 640px;
      border: 1px solid map_get($ui-colors, color-8);
      background: url("../static/imgs/cover-bg.jpg") no-repeat center center / cover;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 5em 3em 6em;
      box-shadow: 1em -1em 0 map_get($ui-colors, color-8);
      @media (max-width: 1439px) {
        height: 580px;
      }
      @media (max-width: 1199px) {
        padding: 3em 2em 5em;
        height: 520px;
      }
      @media (max-width: 850px) {
        height: 480px;
      }
      @media (max-width: 767px) {
        width: 380px;
        height: 430px;
        padding: 2em 1.5em 4em;
        margin-bottom: 3em;
      }
      @media (max-width: 420px) {
        padding-right: 1em;
        padding-left: 1em;
        height: 400px;
      }
      &:after {
        position: absolute;
        content: '';
        width: 3px;
        height: 150px;
        left: 50%;
        bottom: 0;
        transform: translate(-50%,50%);
        background-color: map_get($ui-colors, color-3);
        @media (max-width: 1199px) {
          height: 120px;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      h4 {
        font: bold em(43px) $raleway-bold;
        color: map_get($ui-colors, color-6);
        text-transform: uppercase;
        margin-bottom: 3rem;
        @media (max-width: 1439px) {
          font-size: 2.5em;
        }
        @media (max-width: 1199px) {
          font-size: 2em;
        }
        @media (max-width: 850px) {
          font-size: 1.8em;
        }
        @media (max-width: 420px) {
          font-size: 1.6em;
        }
      }
      p {
        font: em(20px) $raleway-medium-italic;
        color: map_get($ui-colors, color-9);
        line-height: 1.4;
        @media (max-width: 850px) {
          font-size: 1.1rem;
        }
        @media (max-width: 420px) {
          font-size: 1rem;
        }
      }
      .author {
        font: bold 2rem $raleway-bold;
        color: map_get($ui-colors, color-3);
        text-transform: uppercase;
        text-shadow: 2px 2px 1px rgba($black-color,.5);
        margin-top: auto;
        @media (max-width: 850px) {
          font-size: 1.8rem;
        }
        @media (max-width: 578px) {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.blog-section {
  position: relative;
  width: 100%;
  padding: 5em 0 6em;
  z-index: 1;
  @media (max-width: 767px) {
    padding: 3em 0 4.5em;
  }
  &:before {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    height: 0;
    border-top: 130px solid $white-color;
    border-right: 30vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 14vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 100%;
    right: 0;
    height: 0;
    border-top: 150px solid $white-color;
    border-left: 69vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 15vw;
    }
  }
  .section-headline {
    margin-bottom: 3em;
    @media (max-width: 1199px) {
      margin-bottom: 1rem;
    }
  }
  .blog-information {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .image-item {
      position: relative;
      width: 30%;
      border: 1px solid map_get($ui-colors, color-3);
      background-color: map_get($ui-colors, color-7);
      @media (max-width: 1199px) {
        display: none;
      }
      h5 {
        font: 2em $raleway-bold;
        color: map_get($ui-colors, color-4);
        text-align: center;
        padding: 1.5rem 1.5rem 0;
      }
      .float-text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1.5rem;
        font: em(13px) $raleway-bold;
        color: map_get($ui-colors, color-4);
      }
    }
    .news-wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 69%;
      padding: 0 10px 0 1em;
      @media (max-width: 1439px) {
        padding-left: 10px;
      }
      @media (max-width: 1199px) {
        width: 100%;
      }
      @media (max-width: 991px) {
        padding: 0;
      }
      @media (max-width: 767px) and (min-width: 521px) {
        padding: 0 10%;
      }
      .news {
        width: calc(50% - 1.2rem);
        margin-bottom: 2em;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 3em;
        }
        .image {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .more-btn {
    margin: 2em 0 0 50%;
    @media (max-width: 1199px) {
      margin: 2em 0 0;
    }
    .btn {
      width: 270px;
      margin: 0 auto;
    }
  }
}

.news {
  .image {
    margin-bottom: .5em;
  }
  p {
    font-size: em(14px);
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
  h4 {
    font: em(18px) $raleway-bold;
    a {
      color: map_get($ui-colors, color-4);
      transition: all .2s;
      &:hover {
        color: map_get($ui-colors, color-1);
      }
    }
  }
}

.date {
  position: relative;
  font: rem(13px) $raleway-semibold;
  color: map_get($ui-colors, color-4);
  padding-bottom: 5px;
  &:hover {
    color: map_get($ui-colors, color-4);
  }
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 1px;
    width: 85px;
    background-color: map_get($ui-colors, color-4);
  }
  &:after {
    position: absolute;
    content: '\f0da';
    font-family: "FontAwesome";
    top: calc(100% + 5px);
    left: 80px;
    color: map_get($ui-colors, color-4);
    font-size: rem(14px);
  }
}

.contact-section {
  position: relative;
  width: 100%;
  padding: calc(3em + 150px) 0 6em;
  background-color: map_get($ui-colors, color-6);
  @media (max-width: 1199px) {
    padding-bottom: 4em;
  }
  @media (max-width: 767px) {
    padding-top: calc(2em + 15vw);
    padding-bottom: 2em;
  }
  .section-headline {
    align-items: flex-start;
    margin-bottom: 3rem;
    @media (max-width: 1199px) {
      margin-bottom: 1rem;
    }
    span {
      color: $white-color;
      &:after {
        background-color: $white-color;
      }
    }
    h2 {
      color: $white-color;
    }
  }
  .information-block {
    display: flex;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    .contact-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 991px) {
        width: 44%;
        padding-right: 1em;
      }
      @media (max-width: 767px) {
        width: 100%;
        order: 2;
      }
      .info-items {
        padding-top: 1em;
        @media (max-width: 767px) {
          padding-top: 3em;
          text-align: center;
        }
        p {
          font: em(21px) $raleway-medium;
          color: $white-color;
          padding: 0 3rem 1rem 0;
          line-height: 1.5;
          @media (max-width: 1199px) {
            font-size: 1.2em;
            padding-right: 2rem;
          }
          @media (max-width: 991px) {
            font-size: 1em;
            padding-right: 1rem;
          }
          @media (max-width: 767px) {
            padding: 0 0 1rem 0;
            width: 450px;
            max-width: 100%;
            margin: 0 auto;
          }
          @media (max-width: 578px) {
            font-size: em(15px);
          }
        }
        > a {
          display: block;
          font: em(34px) $raleway-bold;
          color: $white-color;
          margin-bottom: 2rem;
          transition: all .2s;
          &:hover {
            color: map_get($ui-colors, color-1);
          }
          @media (max-width: 1199px) {
            font-size: 1.8em;
          }
          @media (max-width: 991px) {
            font-size: 1.6em;
          }
          @media (max-width: 767px) {
            margin-bottom: 1rem;
          }
          @media (max-width: 578px) {
            font-size: 1.4em;
          }
        }
        .socials {
          margin-top: 3em;
          @media (max-width: 767px) {
            margin-top: 2em;
          }
          ul {
            @media (min-width: 768px) {
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .contact-form {
      width: 50%;
      @media (max-width: 991px) {
        width: 55%;
      }
      @media (max-width: 767px) {
        width: 100%;
        order: 1;
      }
      .form {
        .form-group {
          margin-bottom: 2rem;
          &:last-child {
            margin-bottom: 0;
            @media (max-width: 767px) {
              text-align: center;
            }
          }
        }
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: $white-color;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: $white-color;
          opacity: 1;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: $white-color;
          opacity: 1;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $white-color;
        }
      }
    }
  }
}