.select2-container {
  width: 100% !important;
  min-width: 140px;
  font: 1.5rem $raleway-bold;
  @media (max-width: 767px) {
    font-size: 1.3rem;
    height: 65px;
  }
  .select2-results__option {
    font-size: 1rem;
    color: map_get($ui-colors, color-4);
  }

  .select2-selection--single {
    height: 72px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $white-color;
    background-color: transparent;
    box-shadow: none;
    @media (max-width: 767px) {
      height: 65px;
    }
    &[aria-expanded="true"] {
      .select2-selection__arrow {
        b {
          &:before {
            content: '\f0d8';
          }
        }
      }
    }
    .select2-selection__rendered {
      line-height: 72px;
      color: $white-color;
      text-align: left;
      @media (max-width: 767px) {
        height: 65px;
      }
    }
    .select2-selection__placeholder {
      color: $white-color;
    }
    .select2-selection__arrow {
      top: 2px;
      right: 1.5rem;
      b {
        position: relative;
        border: 0;
        &:before {
          content: '\f0d7';
          font-family: FontAwesome;
          color: $white-color;
          font-size: rem(18px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}