.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 10px 15px;
  min-width: 270px;
  min-height: 48px;
  max-width: 100%;
  font-size: rem(14px);
  font-family: $raleway-medium;
  line-height: 1;
  border-radius: 5px;
  transition: all 0.3s;
  text-align: center;
  @media (max-width: 420px) {
    min-width: 250px;
  }
  &:focus,
  &:hover {
    box-shadow: none;
    text-decoration: none;
    //color: map_get($ui-colors, color-6);
  }
  &.btn-default {
    border: 1px solid map_get($ui-colors, color-3);
    color: map_get($ui-colors, color-5);
    background-color: transparent;
    &:hover {
      color: $white-color;
      background-color: map_get($ui-colors, color-3);
    }
  }
}

//label(for="input-btn").btn-label
//input(type="submit", value="enviar").btn-input#input-btn

.btn-label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  min-width: 270px;
  margin: 0;
  &:focus,
  &:hover {
    .btn-input {
      background-color: $white-color;
      color: map_get($ui-colors, color-4);
    }
  }
  .btn-input {
    border: 1px solid $white-color;
    color: $white-color;
    background-color: transparent;
    margin: 0;
    padding: 10px 15px;
    min-width: 270px;
    min-height: 48px;
    max-width: 100%;
    font-size: rem(14px);
    font-family: $raleway-medium;
    line-height: 1;
    border-radius: 5px;
    transition: all 0.3s;
    text-align: center;
  }
}

.menu-btn {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  order: 2;
  margin-left: 3em;
  @media (min-width: 992px) {
    z-index: 10;
  }
}

.menu-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 2px;
  background-color: map_get($ui-colors, color-4);
  transition: background-color 0.3s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: map_get($ui-colors, color-4);
    transition: transform 0.3s, top 0.3s, background-color 0.3s;
  }
  &:before {
    top: 8px;
  }
  &:after {
    top: -8px;
  }
  &.is-active {
    background-color: rgba(255, 255, 255, 0);
  }
  &.is-active:before,
  &.is-active:after {
    top: 0;
  }
  &.is-active:before {
    background-color: map_get($ui-colors, color-4);
    transform: rotate(135deg);
  }
  &.is-active:after {
    background-color: map_get($ui-colors, color-4);
    transform: rotate(225deg);
  }
}

.play-btn {
  //display: block;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px dotted $white-color;
  background-color: $white-color;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  //color: $white-color !important;
  @media (max-width: 991px) {
    width: 72px;
    height: 72px;
  }
  &:hover {
    //color: $white-color;
  }
  .fa {
    color: map_get($ui-colors, color-9);
    font-size: 1.5rem;
    margin-left: 6px;
    @media (max-width: 991px) {
      //font-size: 1.5rem;
    }
  }
}