.main-section {
  &.article {
    .main-text {
      transform: translate(-50%,-50%);
      left: 50%;
      text-align: center;
      padding: 0 15px;
      h1 {
        width: 960px;
        max-width: 100%;
        font: bold 2.95vw $raleway-bold;
        //color: map_get($ui-colors, color-11);
        //font: bold rem(48px) $raleway-bold;
        @media (max-width: 1199px) {
          font-size: 3.3vw;
        }
        @media (max-width: 991px) {
          font-size: 3.8vw;
        }
        @media (max-width: 578px) {
          font-size: 1.3em;
        }
      }
      p {
        line-height: 1.6;
        color: map_get($ui-colors, color-11);
        @media (min-width: 768px) {
          font-size: em(18px);
        }
      }
      .date {
        color: map_get($ui-colors, color-11);
        &:before {
          background-color: map_get($ui-colors, color-11);
        }
        &:after {
          color: map_get($ui-colors, color-11);
        }
      }
    }
  }
}

.article-section {
  position: relative;
  width: 100%;
  padding: 3em 0 0;
  margin-bottom: calc(5em + 215px);
  @media (max-width: 991px) {
    margin-bottom: calc(3em + 13vw);
  }
  .article-block {
    width: 970px;
    max-width: 100%;
    margin: 0 auto;
    h3 {
      font: 1.5em $raleway-bold;
      color: map_get($ui-colors, color-4);
      margin: 1em 0;
      @media (max-width: 767px) {
        font-size: 1.3em;
      }
      @media (max-width: 578px) {
        font-size: 1.2em;
      }
    }
    p {
      @media (min-width: 768px) {
        line-height: 1.7;
      }
      @media (max-width: 578px) {
        font-size: em(15px);
      }
    }
    .image {
      margin-bottom: 1em;
      img {
        width: 100%;
      }
    }
    .quotes {
      quotes: "“" "”";
      padding-left: 1.5em;
      margin-left: 3em;
      border-left: 2px solid #dddddd;
      @media (max-width: 767px) {
        margin-left: 2em;
      }
      @media (max-width: 578px) {
        margin-left: 10px;
        padding-left: 1em;
      }
      @media (max-width: 420px) {
        margin-left: 0;
        padding-left: .7em;
      }
      &:before,
      &:after {
        font-size: 2rem;
        color: map_get($ui-colors, color-1);
        line-height: 0.1em;
        vertical-align: -0.2em;
        margin-right: 0.1em;
      }
      &:before {
        content: open-quote;
      }
      &:after {
        content: close-quote;
      }
    }
    .items {
      list-style: none;
      padding-left: 3em;
      @media (max-width: 767px) {
        padding-left: 2em;
      }
      @media (max-width: 578px) {
        padding-left: 10px;
      }
      @media (max-width: 420px) {
        padding-left: 0;
      }
      li {
        position: relative;
        margin-bottom: .8em;
        padding-left: 1.5em;
        @media (max-width: 578px) {
          padding-left: 1.2em;
          font-size: em(15px);
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '\f05d';
          font-family: 'FontAwesome';
          color: #bbb;
          font-size: rem(20px);
          margin-top: -5px;
          @media (max-width: 578px) {
            font-size: 1rem;
            margin-top: 0;
          }
        }
      }
    }
    .share {
      padding: 0;
      margin: 1.5em 0 0;
      list-style: none;
      @media (min-width: 421px) {
        display: flex;
        justify-content: center;
        margin: 3em 0 0;
      }
      li {
        margin: 0 1em;
        @media (max-width: 420px) {
          & + li {
            margin-top: .5em;
          }
        }
        > a {
          display: flex;
          align-items: center;
          font: em(12px) $raleway-regular;
          color: map_get($ui-colors, color-11);
          @media (max-width: 420px) {
            justify-content: center;
          }
          .fa {
            font-size: rem(18px);
            color: map_get($ui-colors, color-1);
            margin-right: .5em;
          }
        }
      }
    }
  }
}

.socials-section {
  position: relative;
  width: 100%;
  //margin: 4em 0;
  height: 25vw;
  min-height: 260px;
  background-color: map_get($ui-colors, color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    height: 0;
    border-bottom: 215px solid map_get($ui-colors, color-1);
    border-right: 98.8vw solid transparent;
    @media (max-width: 991px) {
      border-bottom-width: 13vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 100%;
    right: 0;
    height: 0;
    border-top: 215px solid map_get($ui-colors, color-1);
    border-left: 98.8vw solid transparent;
    @media (max-width: 991px) {
      border-top-width: 13vw;
    }
  }
  .social-info {
    text-align: center;
    .icon {
      border-radius: 50%;
    }
    p {
      color: $white-color;
      font-size: em(15px);
      margin: 2em 0;
    }
  }
}

.comments-section {
  position: relative;
  width: 100%;
  margin-top: calc(3em + 215px);
  padding-bottom: 3em;
  @media (max-width: 991px) {
    margin-top: calc(2em + 13vw);
  }
  h3 {
    font: 1.5em $raleway-bold;
    color: map_get($ui-colors, color-4);
    margin: 1em 0;
    text-align: center;
    @media (max-width: 578px) {
      font-size: 1.4em;
    }
  }
  .comments-wrapper {
    width: 970px;
    max-width: 100%;
    margin: 0 auto 1em;
    .comment {
      margin-bottom: 2em;
      @media (min-width: 576px) {
        display: flex;
        align-items: flex-start;
      }
      .avatar {
        @media (max-width: 575px) {
          display: flex;
          margin-bottom: 10px;
          img {
            width: 60px;
            height: 63px;
          }
          .signature {
            padding: 10px 1em;

          }
        }
      }
      .content {
        margin-left: 1.5em;
        @media (max-width: 575px) {
          margin-left: 0;
        }
      }
      p {
        font-size: em(14px);
        @media (min-width: 579px) {
          line-height: 1.7;
        }
      }
      .author {
        font: em(13px) $raleway-bold;
        color: map_get($ui-colors, color-4);
      }
      .publish {
        font: em(12px) $raleway-regular;
        color: map_get($ui-colors, color-12);
      }
    }
  }
  .add-comment {
    width: 780px;
    max-width: 100%;
    margin: 0 auto;
    padding: 2em 0 0;
    .comment-message {
      position: relative;
      border-radius: 0;
      background-color: map_get($ui-colors, color-1);
      border: none;
      .msg {
        font: em(15px) $raleway-regular;
        color: $white-color;
        text-align: center;
      }
      .close {
        position: absolute;
        padding: 0;
        top: 45%;
        transform: translateY(-50%);
        right: 10px;
        .fa {
          font-size: 1rem;
          color: map_get($ui-colors, color-6);
        }
      }
    }
    .add-btn {
      text-align: center;
    }
    a {
      width: 100%;
      display: inline-block;
      padding: 12px 15px;
      text-align: center;
      font: em(14px) $raleway-medium;
      color: map_get($ui-colors, color-11);
      border: 1px solid map_get($ui-colors, color-11);
      border-radius: 5px;
      transition: all .2s;
      .fa {
        font-size: rem(20px);
      }
      &.open {
        width: auto;
        border: none;
        border-bottom: 1px solid map_get($ui-colors, color-11);
        border-radius: 0;
        span {
          display: none;
        }
      }
    }
    .form {
      display: none;
      .form-group {
        margin-bottom: 2rem;
      }
      .form-control {
        font: 1rem $raleway-regular;
        color: map_get($ui-colors, color-11);
        border-bottom-color: map_get($ui-colors, color-11);
      }
      .btn-label {
        .btn-input {
          color: map_get($ui-colors, color-11);
          border-color: map_get($ui-colors, color-11);
        }
      }
    }
  }
}

.blog-section {
  &.article {
    padding-top: 0;
    h3 {
      font: 1.5em $raleway-bold;
      color: map_get($ui-colors, color-4);
      margin: 1em 0;
      text-align: center;
      @media (max-width: 578px) {
        font-size: 1.4em;
      }
    }
    .blog-information {
      .news-wrapper {
        width: 780px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .more-btn {
      margin: 5em 0 0;
      @media (max-width: 578px) {
        margin-top: 3em;
      }
      .btn {
        width: 270px;
        margin: 0 auto;
      }
    }
  }
}