.site-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.5s;
  background-color: transparent;
  @media (max-width: 991px) {
    //height: 67px;
  }
  &.on-scroll {
    background-color: $white-color;
    box-shadow: 0 2px 2px rgba($black-color, .65);
  }
  &.is-hidden {
    transform: translateY(-100%);
  }
}

.mobile-menu {
  z-index: 10000;
  margin: 0;
  padding: 2em 1em;
  visibility: hidden;
  opacity: 0;
  min-width: calc(100% + 30px);
  transition: all 0.5s ease;
  background-color: map_get($ui-colors, color-13);
  height: calc(100vh);
  position: absolute;
  top: 0;
  right: -15px;
  transform: translateX(100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;
  @media (max-height: 680px) {
    //justify-content: space-between;
  }
  &.is-open {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
  .logo {
    img {
      @media (max-width: 420px) {
        width: 250px;
      }
    }
  }
  .close-menu {
    .fa {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid map_get($ui-colors, color-4);
      border-radius: 50%;
      margin-bottom: 2.5em;
      font-size: 1.1rem;
      color: map_get($ui-colors, color-4);
      transition: all .2s;
        &:hover {
          color: map_get($ui-colors, color-3);
          border-color: map_get($ui-colors, color-3);
        }
    }
  }
  .socials {
    @media (max-height: 680px) {
      margin-top: auto;
    }
    ul {
      width: 100%;
      .fa {
        color: map_get($ui-colors, color-4);
        font-size: 2em;
      }
    }
  }
}
