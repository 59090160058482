.blog-page-section {
  position: relative;
  width: 100%;
  margin: 0 0 8em;
  padding: 3em 12% 0;
  @media (max-width: 1439px) {
    padding: 3em 6% 0;
  }
  @media (max-width: 1199px) {
    padding: 3em 1% 0;
  }
  @media (max-width: 991px) {
    padding: 3em 3% 0;
  }
  @media (max-width: 767px) {
    padding: 3em 6% 0;
    margin-bottom: 5em;
  }
  @media (max-width: 578px) {
    padding: 3em 0 0;
  }
  .headline-row {
    position: relative;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      margin-bottom: 1em;
    }
    .section-headline {
      align-items: flex-start;
      width: calc(50% - 2em);
      @media (max-width: 991px) {
        width: calc(50% - 1em);
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      h2 {
        margin-bottom: 2rem;
        @media (max-width: 767px) {
          margin-bottom: 1rem;
        }
      }
      p {
        padding-right: 3em;
        line-height: 1.6;
        @media (max-width: 991px) {
          padding-right: 0;
          line-height: 1.5;
        }
      }
    }
  }
  .blog-item-row {
    position: relative;
    display: flex;
    @media (max-width: 767px) {
      margin-bottom: 3em;
    }
    .news {
      width: calc(50% - 2rem);
      margin-top: -10em;
      @media (max-width: 991px) {
        width: calc(50% - 1rem);
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 0;
      }
      .image {
        img {
          width: 100%;
        }
      }
    }
    &:nth-child(2n+1) {
      justify-content: flex-end;
    }
  }
  &.second {
    margin-top: 12em;
    margin-bottom: 0;
    padding-bottom: 6em;
    z-index: 1;
    @media (max-width: 767px) {
      margin-top: 0;
      //margin-bottom: 0;
      padding-bottom: 3em;
    }
    .blog-item-row {
      &:nth-child(2n+1) {
        justify-content: flex-start;
      }
      &:nth-child(2n) {
        justify-content: flex-end;
      }
    }
    &:before {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      height: 0;
      border-top: 130px solid $white-color;
      border-right: 30vw solid transparent;
      @media (max-width: 767px) {
        border-top-width: 14vw;
      }
    }
    &:after {
      position: absolute;
      content: '';
      top: 100%;
      right: 0;
      height: 0;
      border-top: 150px solid $white-color;
      border-left: 69vw solid transparent;
      @media (max-width: 767px) {
        border-top-width: 15vw;
      }
    }
    .more-btn {
      position: absolute;
      bottom: 5em;
      right: 10%;
      @media (max-width: 767px) {
        position: relative;
        right: 0;
        bottom: 0;
        .btn {
          width: 270px;
          margin: 0 auto;
        }
      }
    }
  }

}