.arrows {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.books-slider {
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 0 4%;
  margin-top: -12em;
  @media (max-width: 1199px) {
    padding: 0;
    margin-top: -8em;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
  .arrows {
    top: calc(100% + 3em);
    width: 32px;
    height: 32px;
    border: 1px solid map_get($ui-colors, color-3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    transition: all .2s;
    &.left {
      left: calc(50% - 3em);
    }
    &.right {
      left: calc(50% + 3em);
    }
  }
  > .fa {
    font-size: rem(14px);
    color: map_get($ui-colors, color-3);
    &:hover {
      color: $white-color;
      background-color: map_get($ui-colors, color-3);
    }
  }
}
