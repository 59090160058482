.main-section {
  &.internal-page {
    height: 56vw;
    &.blue {
      &:before {
        border-bottom-color: map_get($ui-colors, color-1);
      }
      &:after {
        border-bottom-color: map_get($ui-colors, color-1);
      }
    }
  }
}

.consulta-text-section {
  position: relative;
  width: 100%;
  background-color: map_get($ui-colors, color-1);
  padding: 6em 0;
  @media (max-width: 767px) {
    padding: 4.5em 0;
  }
  @media (max-width: 578px) {
    padding: 3em 0;
  }
  &:before {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    height: 0;
    border-top: 130px solid map_get($ui-colors, color-1);
    border-right: 30vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 14vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 100%;
    right: 0;
    height: 0;
    border-top: 150px solid map_get($ui-colors, color-1);
    border-left: 69vw solid transparent;
    @media (max-width: 767px) {
      border-top-width: 15vw;
    }
  }
  .text-block {
    width: 1090px;
    max-width: 100%;
    margin: 0 auto;
    p {
      font: em(24px) $raleway-medium;
      color: $white-color;
      text-align: center;
      @media (max-width: 1199px) {
        font-size: 1.2em;
      }
      @media (max-width: 991px) {
        font-size: 1em;
      }
      @media (max-width: 578px) {
        font-size: em(15px);
      }
    }
  }
}

.consultation-section {
  &.consulta-page {
    @media (max-width: 767px) {
      padding-bottom: 3em;
    }
    &:before {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      height: 0;
      border-top: 130px solid $white-color;
      border-right: 30vw solid transparent;
      z-index: 1;
      @media (max-width: 767px) {
        border-top-width: 14vw;
      }
    }
    &:after {
      position: absolute;
      content: '';
      top: 100%;
      right: 0;
      height: 0;
      border-top: 150px solid $white-color;
      border-left: 69vw solid transparent;
      z-index: 1;
      @media (max-width: 767px) {
        border-top-width: 15vw;
      }
    }
  }
}

.consultation-wrapper {
  position: relative;
  width: 1150px;
  max-width: 100%;
  margin: 0 auto;
  .consult-item {
    display: flex;
    margin-bottom: 4em;
    @media (max-width: 991px) {
      justify-content: center;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      width: 520px;
      max-width: 100%;
      margin: 0 auto 3em;
    }
    .consulta-info {
      background-color: $white-color;
      width: 58%;
      padding: 2em;
      @media (max-width: 1024px) {
        width: 56%;
        padding: 1em 2em;
      }
      @media (max-width: 991px) {
        z-index: 1;
        margin: 1rem 0 1rem -8rem;
      }
      @media (max-width: 767px) {
        order: 2;
        width: 100%;
        margin: -12rem 0 0 2rem;
      }
      @media (max-width: 490px) {
        margin-left: 1rem;
        margin-top: -8rem;
      }
      @media (max-width: 420px) {
        padding: 1em;
      }
      p {
        font: em(14px) $raleway-regular;
        color: map_get($ui-colors, color-11);
        line-height: 1.75;
      }
      .btn {
        width: 270px;
        margin-top: 3em;
      }
      .item-info {
        position: relative;
        padding-bottom: .6em;
        margin-bottom: 2.5em;
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          height: 1px;
          width: 85px;
          background-color: map_get($ui-colors, color-4);
        }
        &:after {
          position: absolute;
          content: '\f0d9';
          font-family: "FontAwesome";
          top: calc(100% + 5px);
          left: 0;
          color: map_get($ui-colors, color-4);
          font-size: rem(14px);
        }
        h4 {
          font: bold 1.5rem $raleway-bold;
          a {
            transition: all 0.2s;
            color: map_get($ui-colors, color-4);
            &:hover {
              color: map_get($ui-colors, color-1);
              text-decoration: underline;
            }
          }
          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
          @media (max-width: 578px) {
            font-size: 1.2em;
          }
        }
        span {
          font-size: em(14px);
          margin-bottom: 0;
          @media (max-width: 578px) {
            font-size: em(13px);
          }
          @media (min-width: 371px) {
            //margin-left: 2.5rem;
          }
        }
      }
    }
    .image {
      width: 42%;
      max-width: 100%;
      @media (max-width: 1024px) {
        width: 44%;
      }
      @media (max-width: 991px) {
        width: 425px;
      }
      @media (max-width: 767px) {
        width: 520px;
        height: 600px;
        order: 1;
      }
      @media (max-width: 520px) {
        height: 110vw;
      }
      img {
        width: 100%;
      }
    }
    &.reverse {
       .consulta-info {
         @media (max-width: 991px) {
           margin: 1rem -8rem 1rem 0;
         }
         @media (max-width: 767px) {
           margin: -12rem 0 0 2rem;
         }
         @media (max-width: 490px) {
           margin-left: 1rem;
           margin-top: -8rem;
         }
         @media (min-width: 768px) {
           text-align: right;
           .btn {
             margin-left: auto;
           }
           .item-info {
             &:before,
             &:after {
               left: auto;
               right: 0;
             }
             &:after {
               content: '\f0da';
             }
           }
         }
       }
     }
  }
}


.consult-request {
  position: relative;
  width: 100%;
  padding: calc(4em + 140px) 0;
  background-color: map_get($ui-colors, color-4);
  @media (max-width: 767px) {
    padding: calc(4em + 14vw) 0;
  }
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 0;
    border-bottom: 140px solid map_get($ui-colors, color-10);
    border-right: 70vw solid transparent;
    @media (max-width: 767px) {
      border-bottom-width: 14vw;
    }
  }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    height: 0;
    border-bottom: 130px solid map_get($ui-colors, color-10);
    border-left: 30vw solid transparent;
    @media (max-width: 767px) {
      border-bottom-width: 14vw;
    }
  }
  .section-headline {
    //align-items: flex-start;
    h2 {
      color: $white-color;
    }
    span {
      color: $white-color;
    }
  }
  form {
    width: 570px;
    max-width: 100%;
    margin: 3em auto 0;
    .form-group {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $white-color;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $white-color;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $white-color;
      opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white-color;
    }
  }
}