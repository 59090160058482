html {
  font-size: 16px;
}
body {
  font-family: $raleway-regular;
  color: map_get($ui-colors, color-11);
  background-color: $white-color;
  font-size: 1em;
  a,
  a:hover,
  a:focus {
    text-decoration: none;
    outline: none;
  }
  &.overflow-hidden {
    /* when primary navigation is visible, the content in the background won't scroll */
    overflow: hidden;
  }
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .to-bg {
    opacity: 0;
  }
}

.container {
  @media (min-width: 579px) {
    width: 720px;
    max-width: 100%
  }
  @media (min-width: 768px) {
    width: 800px;
    max-width: 100%
  }
  @media (min-width: 992px) {
    width: 960px;
    max-width: 100%
  }
  @media (min-width: 1200px) {
    width: 1140px;
    max-width: 100%
  }
  @media (min-width: 1440px) {
    width: 1390px;
    max-width: 100%;
  }
}

.wrapper {
  position: relative;
  width: 1380px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: 992px) {
    padding: 0 30px;
  }
}

.top-margin {
  position: relative;
  width: 100%;
  //height: 15px;
  padding-top: 2em;
  overflow: hidden;
  cursor: default;
  transition: all 0.2s;
  background-color: map_get($ui-colors, color-7);
  @media (max-width: 991px) {
    //height: 67px;
  }
  &.scroll {
    //height: 67px;
  }
}

.section-headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    position: relative;
    font: em(13px) $raleway-semibold;
    color: map_get($ui-colors, color-4);
    padding-bottom: 2px;
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 84px;
      height: 1px;
      background-color: map_get($ui-colors, color-4);
    }
  }
  h2 {
    font: bold em(48px) $raleway-bold;
    color: map_get($ui-colors, color-4);
    text-transform: uppercase;
    padding-left: 1rem;
    @media (max-width: 991px) {
      font-size: 2.7em;
    }
    @media (max-width: 767px) {
      font-size: 2.3em;
    }
    @media (max-width: 578px) {
      font-size: 2em;
    }
    @media (max-width: 420px) {
      //font-size: 1.6em;
    }
  }
}



.socials {
  ul  {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      margin: 0 1.6em ;
    }
    .fa {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 28px;
      font-size: 2rem;
      color: $white-color;
      background-color: transparent;
      transition: all 0.2s;
      @media (max-width: 767px) {
        font-size: 1.6rem;
      }
      &.fa-facebook:hover {
        color: map_get($ui-colors, color-1);
      }
      &.fa-twitter:hover {
        color: map_get($ui-colors, color-1);
      }
      &.fa-instagram:hover {
        color: map_get($ui-colors, color-1);
      }
    }
  }
  &.footer {
    ul {
      .fa {
        width: 40px;
        height: 28px;
        font-size: rem(22px);
        color: map_get($ui-colors, color-1);
        @media (max-width: 767px) {
          //font-size: 1.5rem;
        }
      }
    }
  }
}

