.main-section {
  &.internal-page {
    height: 56vw;
    &:before {
      border-bottom-color: $white-color;
    }
    &:after {
      border-bottom-color: $white-color;
    }
  }
}

.about-page-section {
  position: relative;
  width: 100%;
  padding: 5em 5% 3em;
  @media (max-width: 1199px) {
    padding: 5em 1% 3em;
  }
  @media (max-width: 767px) {
    padding: 2em 0;
  }
  > .wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .about-img {
    width: 45%;
    @media (max-width: 1199px) and (min-width: 992px) {
      width: 40%;
    }
    @media (max-width: 767px) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .about-head {
    width: 55%;
    padding: 2em 3em 2em 4em;
    @media (max-width: 1199px) {
      padding: 1em 1em 1em 3em;
    }
    @media (max-width: 991px) {
      padding-left: 2em;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding: 1em 0;
    }
    .section-headline {
      align-items: flex-start;
      margin-bottom: 3em;
      @media (max-width: 1199px) {
        margin-bottom: 2em;
      }
      @media (max-width: 991px) {
        margin-bottom: 1em;
      }
      span {
        &:after {
          background-color: map_get($ui-colors, color-1);
        }
      }
    }
    .mini-info {
      font: bold 1.5em $raleway-bold;
      color: map_get($ui-colors, color-4);
      @media (max-width: 1199px) {
        font-size: 1.3em;
      }
      @media (max-width: 991px) {
        font-size: 1.2em;
      }
      @media (max-width: 578px) {
        font-size: 1.05em;
      }
    }
  }
  .about-info {
    width: 100%;
    //display: flex;
    @media (min-width: 768px) {
      margin-top: 4em;
      display: flex;
    }
    > p {
      line-height: 1.6;
      @media (min-width: 992px) {
        width: 45%;
      }
      @media (min-width: 768px) {
        line-height: 1.7;
      }
      @media (max-width: 578px) {
        font-size: em(15px);
      }
      &:last-child {
        padding-left: 4em;
        @media (max-width: 1199px) {
          padding-left: 3em;
        }
        @media (max-width: 991px) {
          padding-left: 2em;
        }
        @media (max-width: 767px) {
          padding-left: 0;
        }
      }
    }
  }
  .about-complete-info {
    width: 100%;
    h3 {
      font: 1.5em $raleway-bold;
      color: map_get($ui-colors, color-4);
      margin: 1em 0;
      @media (max-width: 578px) {
        margin: .7em 0;
        font-size: 1.3em;
      }
    }
    p {
      line-height: 1.6;
      @media (min-width: 768px) {
        line-height: 1.7;
      }
      @media (max-width: 578px) {
        font-size: em(15px);
      }
    }
  }
}

.milestones-section {
  position: relative;
  padding: 3em 0 0;
  margin: 0 auto 10em;
  width: 1560px;
  max-width: 100%;
  display: flex;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    margin-bottom: 3em;
  }
  .section-headline {
    align-items: flex-start;
    margin-bottom: 2rem;
    @media (max-width: 991px) {
      padding-left: 15px;
    }
    span {
      &:after {
        background-color: map_get($ui-colors, color-1);
      }
    }
  }
  .milestone-block {
    width: 35%;
    padding-left: 2%;
    @media (max-width: 1199px) {
      width: 40%;
    }
    @media (max-width: 991px) {
      width: 100%;
      order: 2;
      padding: 2.5em 2em 0;
    }
    @media (max-width: 578px) {
      padding: 2em 0 0;
    }
  }
  .milestone-text {
    max-height: 580px;
    overflow-y: auto;
    padding-right: 1em;
    @media (max-width: 991px) {
      max-height: 520px;
    }
    > p {
      margin-bottom: 3rem;
      @media (max-width: 991px) {
        margin-bottom: 2rem;
      }
      @media (max-width: 578px) {
        font-size: em(15px);
        padding-right: 1.2em;
      }
    }
    h3 {
      font: 1.5em $raleway-bold;
      color: map_get($ui-colors, color-4);
      margin-bottom: 1rem;
      @media (max-width: 420px) {
        font-size: 1.2em;
      }
    }
    h5 {
      font: em(14px) $raleway-regular;
      color: map_get($ui-colors, color-12);
      font-weight: bold;
    }
    p.text {
      font-size: em(14px);
      margin-bottom: 1rem;
    }
  }
  .image {
    position: relative;
    width: 65%;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: 2.5em;
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      width: 60%;
      margin-left: 1.5em;
    }
    @media (max-width: 991px) {
      width: 100%;
      order: 1;
      margin-left: 0;
      padding-right: 0;
      padding-top: 2em;
    }
    @media (max-width: 767px) {
      padding-top: 1em;
      padding-left: 1em;
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      height: 65%;
      background-color: map_get($ui-colors, color-1);
      z-index: -1;
      @media (max-width: 991px) {
        height: 95%;
      }
    }
    .inner-img {
      width: 865px;
      max-width: 100%;
      height: 570px;
      @media (max-width: 991px) {
        width: 100%;
        height: 57vw;
      }
    }
  }
}