.form {
  .form-group {
    border: 0;
    margin-bottom: 1rem;
    textarea {
      height: auto;
      &.form-control-danger {
        background-image: none;
      }
    }
    &.has-danger {
      margin-bottom: 0 !important;
      + label.help-block {
        width: 100%;
        margin: 5px 0 1rem;
        font-size: em(13px);
        color: $error-color;
        letter-spacing: 1.1px;
        background-color: transparent;
        text-align: left;
        @media (max-width: 420px) {
          font-size: 0.8em;
        }
      }
      .form-control {
        border-bottom-color: $error-color !important;
      }
      .select2-selection {
        border-color: $error-color !important;
      }
    }
  }
}

.form-control {
  font: 1.5rem $raleway-bold;
  background-color: transparent;
  border-radius: 0;
  //border-color: transparent;
  border: none;
  border-bottom: 1px solid $white-color;
  box-shadow: none;
  height: 72px;
  color: $white-color;
  @media (max-width: 767px) {
    font-size: 1.3rem;
    height: 65px;
  }
  &:focus {
    background-color: transparent;
    color: $white-color;
  }
}
