.navigation {
  position: relative;
  width: 100%;
  padding: 1.5em 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all .5s;
  @media (max-width: 1199px) {
    //padding: 0 1em;
  }
  > .logo {
    margin-right: auto;
    img {
      transition: all .5s;
      max-width: 100%;
      @media (max-width: 991px) {
        width: 250px;
      }
      @media (max-width: 767px) {
        width: 220px;
      }
    }
  }
  &.on-scroll {
    padding-top: 10px;
    > .logo {
      &.white {
        display: none;
      }
      &.black {
        display: block;
      }
      img {
        width: 180px;
      }
    }
  }
  &.white {
    > .logo {
      &.white {
        display: block;
      }
      &.black {
        display: none;
      }
    }
    .menu-icon {
      background-color: $white-color;
      &:before,
      &:after {
        background-color: $white-color;
      }
      &.is-active {
        background-color: rgba(255, 255, 255, 0);
      }
      &.is-active:before {
        background-color: $white-color;
      }
      &.is-active:after {
        background-color: $white-color;
      }
    }
    .main-menu {
      > li {
        @media (min-width: 992px) {
          > a {
            color: $white-color;
          }
          &:hover {
            > a {
              color: map_get($ui-colors, color-10);
            }
          }
        }
      }
    }
  }
}

[role^='navigation'] {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    margin-bottom: 0;
  }
}

.main-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  @media (min-width: 992px) {
    order: 1;
    //z-index: 1;
    transform: translateX(100%);
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s;
    &.is-visible {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @media (max-width: 991px) {
    margin: 3em 1em 2em;
  }
  > li {
    &:hover {
      > a {
        color: map_get($ui-colors, color-10);
      }
    }
    @media (min-width: 992px) {
      position: relative;
      float: left;
      padding: 0 1.5em;
      @media (max-width: 1090px) {
        padding: 0 1em;
      }
      > a {
        transition: all 0.2s;
        color: map_get($ui-colors, color-4);
        font: 1rem $raleway-semibold;
        text-transform: uppercase;
      }

    }
    @media (max-width: 991px) {
      margin-bottom: 2em;
      > a {
        display: block;
        font: em(20px) $raleway-semibold;
        padding: 0;
        color: map_get($ui-colors, color-4);
        text-transform: uppercase;
        transition: all 0.2s;
      }
    }
    @media (max-width: 420px) {
      margin-bottom: 1.5em;
    }
  }
}


