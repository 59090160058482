@charset "utf-8";

/*===[ FONTS ]===*/
@import "base/fonts";
@import "base/reset";

/*===[ /UTILS ]===*/
@import "utils/mixins";
@import "utils/variables";
@import "utils/functions";

/*===[ VENDOR-EXTENSIONS ]===*/
@import "vendors-extensions/select2";
//@import "vendors-extensions/fancybox";


/*===[ COMPONENTS ]===*/
@import "components/buttons";
@import "components/carousels";
@import "components/modals";
@import "components/videos";
//@import "components/accordions";
//@import "components/breadcrumbs";


/*===[ LAYOUTS ]===*/
@import "layout/globals";
@import "layout/header";
@import "layout/footer";
@import "layout/navigation";
@import "layout/forms";

/*===[ /PAGES ]===*/
@import "pages/home";
@import "pages/error-thanks";
@import "pages/about-me";
@import "pages/blog";
@import "pages/blog-article";
@import "pages/contact";
@import "pages/consultas";
