.main-section {
  &.internal-page {
    height: 56vw;
    &.black {
      &:before {
        border-bottom-color: map_get($ui-colors, color-6);
      }
      &:after {
        border-bottom-color: map_get($ui-colors, color-6);
      }
    }
  }
}

.contact-section {
  &.contact-page {
    padding: 3em 0 calc(6em + 140px);
    @media (max-width: 767px) {
      padding-bottom: calc(3em + 14vw);
    }
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 0;
      border-bottom: 140px solid $white-color;
      border-right: 70vw solid transparent;
      @media (max-width: 767px) {
        border-bottom-width: 14vw;
      }
    }
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      height: 0;
      border-bottom: 130px solid $white-color;
      border-left: 30vw solid transparent;
      @media (max-width: 767px) {
        border-bottom-width: 14vw;
      }
    }
  }
}

.blog-section {
  &.contact-page {
    padding-bottom: calc(3em + 140px);
    @media (max-width: 767px) {
      padding-bottom: calc(4em + 14vw);
    }
    &:before {
      top: auto;
      bottom: 0;
      border-top: 0 solid transparent;
      border-right: 70vw solid transparent;
      border-bottom: 140px solid map_get($ui-colors, color-10);
      @media (max-width: 767px) {
        border-bottom-width: 14vw;
      }
    }
    &:after {
      top: auto;
      bottom: 0;
      border-top: 0 solid transparent;
      border-bottom: 130px solid map_get($ui-colors, color-10);
      border-left: 30vw solid transparent;
      @media (max-width: 767px) {
        border-bottom-width: 14vw;
      }
    }
  }
}