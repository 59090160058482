footer {
  position: relative;
  width: 100%;
  padding: 0 0 3em;
  background-color: map_get($ui-colors, color-6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding-bottom: 2em;
  }
  .back-to-top {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: 1px solid map_get($ui-colors, color-3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all .2s;
    &:hover {
      background-color: map_get($ui-colors, color-3);
      .fa {
        color: $white-color;
      }
    }
    .fa {
      font-size: em(14px);
      color: map_get($ui-colors, color-3);
      transition: all .2s;
    }
  }
  &.single-page {
    height: 80px;
    padding: 0;
    justify-content: flex-start;
    @media (max-width: 578px) {
      height: 90px;
    }
    > .wrapper {
      @media (max-width: 578px) {
        padding-top: 10px;
      }
    }
    &.dark {
      background-color: map_get($ui-colors, color-4);
    }
    &.blue {
      background-color: map_get($ui-colors, color-10);
    }
    .copyright {
      margin-top: -2em;
      @media (max-width: 420px) {
        margin-top: -1em;
      }
      &:before {
        transform: none;
        left: 0;
      }
    }
  }
  &.other-page {
    height: 135px;
    padding: 0;
    justify-content: flex-start;
    @media (max-width: 420px) {
      height: 150px;
    }
    > .wrapper {
      @media (max-width: 578px) {
        padding-top: 10px;
      }
    }
    &.blue {
      background-color: map_get($ui-colors, color-10);
    }
    .copyright {
      margin-top: -2em;
      padding-bottom: 3em;
      @media (max-width: 767px) {
        padding-bottom: 2em;
      }
      @media (max-width: 420px) {
        margin-top: -1em;
      }
      &:before {
        transform: none;
        left: 0;
      }
    }
  }
}

.copyright {
  position: relative;
  padding-top: 1em;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 85px;
    background-color: $white-color;
  }
  p {
    font-size: em(14px);
    margin-bottom: 0;
    color: $white-color;
    @media (max-width: 420px) {
      font-size: em(13px);
    }
    a {
      color: $white-color;
      font-family: $raleway-medium;
      font-weight: bold;
      transition: all .2s;
      &:hover {
        color: $ireevo-color;
      }
      //text-decoration: underline;
    }
  }
  &.text-center {
    padding-left: 15px;
    padding-right: 15px;
    &:before {
      @media (max-width: 767px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    p {
      @media (max-width: 420px) {
        font-size: em(14px);
      }
    }
  }
}